// Sets Global Styles
// ==============================================================
// Language: scss

html,
body,
#root,
.App {
  height: 100%;
}

html,
body {
  font-family: "Inter", "Circular", sans-serif !important;
}

// input,
.input-container {
  background-color: white;

  input {
    background-color: white;
  }
}
