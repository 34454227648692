/*
 * Utility Styles (global)
 */
.px-60 {
  padding-left: 60px;
  padding-right: 60px;

  @media (max-width: 767px) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.img-fit {
  display: block;
  object-fit: cover;
  object-position: center;
}

//custom table container
.table-container {
  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
    overflow-x: auto;
    max-width: 760px;
  }
}
